import React from "react"
import styled from "styled-components"
import About from "./About"
import Toc from "./Toc"
// import Instagram from "./Instagram"
import Recent from "./Recent"
import BannerCategories from "./BannerCategories"
const Banner = ({ post, tableOfContents }) => {
  return (
    <Wrapper>
      {tableOfContents && post === "post" ? (
        <Toc tableOfContents={tableOfContents} />
      ) : (
        <About />
      )}
      {/* <Instagram /> */}
      <Recent />
      <BannerCategories />
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  display: grid;
  grid-template-columns: 200px;
  justify-content: center;
  row-gap: 1rem;
  @media (min-width: 576px) {
    & {
      grid-template-columns: repeat(auto-fit, 200px);
      column-gap: 3rem;
    }
  }
`

export default Banner
