import React from "react"
import SocialLinks from "../../constants/socialLinks"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Title from "./Title"
import styled from "styled-components"
import { Link } from "gatsby"

const query = graphql`
  {
    file(
      relativePath: {
        eq: "The Digital Specs from Photo by fauxels from Pexels.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
const About = () => {
  const data = useStaticQuery(query)
  return (
    <Wrapper>
      <Title title="about us" />
      <Image
        fixed={data.file.childImageSharp.fixed}
        width="1200"
        className="img"
      />

      <p>
        The Digital Specs is owned by Ecronsoft App Pvt Ltd. In our blog, we
        used to share our research and help you to choose find useful app info
        of phisical and digital products
        <Link to="/about">
          <strong> More...</strong>
        </Link>
      </p>
      <SocialLinks styleClass="banner-icons"></SocialLinks>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  p {
    color: var(--clr-grey-5);
  }
  .img {
    border-radius: 50%;
  }
`
export default About
