import React from "react"
// import SocialLinks from "../../constants/socialLinks"
// import Image from "gatsby-image"
//import { graphql } from "gatsby"
import Title from "./Title"
import styled from "styled-components"
// import { Link } from "gatsby"
// import { H1, H2, P } from "../../components/page-elements"

// const query = graphql`
//   {
//     file(relativePath: { eq: "banner-about.jpeg" }) {
//       childImageSharp {
//         fixed(width: 100, height: 100) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
const Toc = ({ tableOfContents }) => {
  //const data = useStaticQuery(query)
  // const idList = getIds(tableOfContents.items)
  // const activeId = useActiveId(idList)
  return (
    <Wrapper>
      <Title title="Contents" />
      {typeof tableOfContents.items === "undefined" ? null : (
        <div>
          <InnerScroll>
            {/* <details open>
              <summary>Table of Contents</summary>
              {renderItems(tableOfContents.items, activeId)}
            </details> */}
            {/* <summary> TABLE OF CONTENTS</summary> */}
            {tableOfContents.items.map(i => (
              <li key={i.url}>
                <a href={i.url} key={i.url}>
                  {i.title}
                </a>
                {i.items && renderItems(i.items)}
              </li>
            ))}

            {/* <ol>
              {tableOfContents.items.map(item => {
                return (
                  <li key={item.url}>
                    <a
                      href={item.url}
                      style={{
                        color:
                          activeId === item.url.slice(1) ? "tomato" : "green",
                      }}
                    >
                      {item.title}
                    </a>
                    {item.items && renderItems(item.items, activeId)}
                  </li>
                )
              })}
            </ol> */}
          </InnerScroll>
        </div>
      )}
    </Wrapper>
  )
}

// const getIds = items => {
//   return items.reduce((acc, item) => {
//     if (item.url) {
//       // url has a # as first character, remove it to get the raw CSS-id
//       acc.push(item.url.slice(1))
//     }
//     if (item.items) {
//       acc.push(...getIds(item.items))
//     }
//     return acc
//   }, [])
// }
const renderItems = (i, activeId) => {
  return (
    <ol>
      {i &&
        i.map(j => {
          return (
            <li key={j.url}>
              <a
                href={j.url}
                style={
                  {
                    //color: activeId === item.url.slice(1) ? "tomato" : "green",
                  }
                }
              >
                {j.title}
              </a>
              {j.items && renderItems(j.items, activeId)}
            </li>
          )
        })}
    </ol>
  )
}

// const useActiveId = itemIds => {
//   const [activeId, setActiveId] = useState(`test`)
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       entries => {
//         entries.forEach(entry => {
//           if (entry.isIntersecting) {
//             setActiveId(entry.target.id)
//           }
//         })
//       },
//       { rootMargin: `0% 0% -80% 0%` }
//     )
//     itemIds.forEach(id => {
//       observer.observe(document.getElementById(id))
//     })
//     return () => {
//       itemIds.forEach(id => {
//         observer.unobserve(document.getElementById(id))
//       })
//     }
//   }, [itemIds])
//   return activeId
// }

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
`

const Wrapper = styled.div`
  text-align: center;
  p {
    color: var(--clr-grey-5);
  }
  .img {
    border-radius: 50%;
  }
`
export default Toc
